import React, { useState, useEffect, useRef } from 'react';
import "./Skincare.css"
import ProductCard from '../../components/productCard/ProductCard';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, filter } from '@chakra-ui/react';
import { changePage, setIsLoading, setList } from '../../../features/Search';
import axios from 'axios';
import Header from '../../components/Header/Header';
import { useTypewriter } from 'react-simple-typewriter';
import Copyrights from '../../components/copyrights/Copyrights';
import { useNavigate } from 'react-router-dom';

function Skincare(props) {


    const [text] = useTypewriter({
        words: ['...'],
        loop: {},
        typeSpeed: 200,
        delaySpeed: 200
    })
    var filteredProducts = useSelector((state) => state.searchStore.filteredProducts)
    var products = useSelector((state) => state.searchStore.products)
    const isLoading = useSelector((state) => state.searchStore.isLoading)
    const dispatch = useDispatch();

    const totalNumberOfPages = Math.floor(products.length / 9) + 1;

    const [pageNumber, setPageNumber] = useState(0);


    const [cached, setCached] = useState(false);

    const updateStateAndURL = (newStateValue) => {
        setPageNumber(newStateValue);
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set('pageNumber', newStateValue);
        window.history.replaceState(null, '', `?${queryParams.toString()}`);
        dispatch(changePage(newStateValue))
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const initialState = queryParams.get('pageNumber');
        if (initialState) {
            setPageNumber(initialState);
        }
         dispatch(changePage(initialState))
    }, [dispatch]);


    const lastRequestRef = useRef(null);

const navigate=useNavigate();
    useEffect(() => {
        if(products.length<50){
            dispatch(setIsLoading(true))
            
            const cachedData = localStorage.getItem('cachedData');
            if (cachedData) {
                setCached(true)
                const parsedData = JSON.parse(cachedData);
                const first50Products = parsedData.slice(0, 20);
                dispatch(setList(first50Products));
                updateStateAndURL(1)
                dispatch(setIsLoading(false))    
            }
            
            
            var token = localStorage.getItem("token")
            const headers = {
                'Authorization': `Bearer ${token}`
            };
    
            const source = axios.CancelToken.source();
            lastRequestRef.current = source;
    
            axios
                .post(`${process.env.REACT_APP_HOSTURL_PROD}/skincare/find`, {
                    search: "",
                }, { headers, cancelToken: source.token })
                .then((res) => {
    
                    const first50Products = res.data.products.slice(0, 20);
                    dispatch(setList(res.data.products));
                    updateStateAndURL(1)
                    dispatch(setIsLoading(false))
                    setCached(false)
                    localStorage.setItem('cachedData', JSON.stringify(first50Products));
                })
                .catch((e) => {
if(e.response){
    if(e.response.status===401){
        navigate("/signin")
    }
}
                 
                });
        }else{
            updateStateAndURL(1)

        }

        return () => {
            if (lastRequestRef.current) {
                lastRequestRef.current.cancel();
            }
        };
    }, [dispatch]);

    return (
        
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", height: "100%" }}>
                <Header updateUrl={updateStateAndURL} wordsList={props.wordsList} lastRef={lastRequestRef} setCached={setCached}/>
                <br></br>
                {
                    isLoading ? <div style={{ display: "flex", justifyContent: "center", alignItems: 'center', height: "50vh", width: "100%" }}>
                        <Spinner size='xl' />
                    </div>
                        : <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }}>
                            {
                                filteredProducts&&filteredProducts.map((e, index) => {

                                    return <ProductCard key={e.id} product={e} />


                                })
                            }

                        </div>

                }
                {!isLoading && cached && <div><p>This is cached data still retrieving full data{text}</p><p>(May take over 2 minutes)</p></div>}
                {
                    !isLoading && <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div className={pageNumber === 1 ? "inactive-chevron" : "active-chevron"} onClick={() => {
                            if (pageNumber !== 1) {
                                updateStateAndURL(pageNumber - 1)

                            }
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g clipPath="url(#clip0_40_12)">
                                    <path d="M15.41 16.59L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.59Z" fill="black" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_40_12">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        {totalNumberOfPages &&
                            Array.from({ length: totalNumberOfPages }, (_, index) => {
                                const pageNumberInt = Number.parseInt(pageNumber);
                                const indexInt = Number.parseInt(index);

                                const isActive = indexInt + 1 === pageNumberInt;
                                const isInRange = Math.abs(indexInt + 1 - pageNumberInt) <= 2;

                                if (isInRange) {
                                    return (
                                        <div
                                            className={isActive ? 'active-number-div' : 'number-div'}
                                            style={isActive ? { border: "1px solid #ff933b", background: "var(--para-color)" } : null}
                                            key={index}
                                            onClick={() => {
                                                updateStateAndURL(index + 1);
                                            }}
                                        >
                                            {index + 1}
                                        </div>
                                    );
                                } else if (indexInt === totalNumberOfPages - 1) {
                                    // Last page
                                    return (
                                        <>
                                            {indexInt - 2 < totalNumberOfPages - 1 && (
                                                <div className="ellipsis" key={`ellipsis-after-${index}`}>
                                                    ...
                                                </div>
                                            )}
                                            <div
                                                className={isActive ? 'active-number-div' : 'number-div'}
                                                key={index}
                                                onClick={() => {
                                                    updateStateAndURL(index + 1);
                                                }}
                                            >
                                                {index + 1}
                                            </div>

                                        </>
                                    );
                                } else if (indexInt === 0) {
                                    return (
                                        <>
                                            <div
                                                className={isActive ? 'active-number-div' : 'number-div'}
                                                key={index}
                                                onClick={() => {
                                                    updateStateAndURL(index + 1);
                                                }}
                                            >
                                                {index + 1}
                                            </div>
                                            {indexInt + 2 < totalNumberOfPages - 1 && (
                                                <div className="ellipsis" key={`ellipsis-before-${index}`}>
                                                    ...
                                                </div>
                                            )}
                                        </>
                                    );
                                }else{
                                    return (<div></div>)
                                }
                            })
                        }


                        <div className={pageNumber === totalNumberOfPages ? "inactive-chevron" : "active-chevron"} onClick={() => {
                            if (pageNumber !== totalNumberOfPages) {
                                updateStateAndURL(pageNumber + 1)
                            }

                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g clipPath="url(#clip0_40_29)">
                                    <path d="M8.59 16.59L13.17 12L8.59 7.41L10 6L16 12L10 18L8.59 16.59Z" fill="black" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_40_29">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                }


                <Copyrights />
            </div>
    );
}

export default Skincare;