import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
function Copyrights(props) {
    return (
        <div style={{ backgroundColor: "#1F1F1F", padding: 10,marginTop:20 }}>
          <span style={{ color: "white", fontWeight: "bold" }}>MADE WITH <FontAwesomeIcon style={{ color: 'red' }} icon={faHeart} /><span style={{ cursor: "pointer" }} onClick={() => {
            window.open("https://www.instagram.com/slim_skhab", '_blank');
          }}> BY SLIM SKHAB</span></span>
        </div>
    );
}

export default Copyrights;