import { createSlice } from "@reduxjs/toolkit";
const searchSlice=createSlice({
    name:"search",
    initialState:{
        isLoading:false,
        products:[],
        filteredProducts:[],
    },
    reducers:{
        clearList:(state)=>{
            state.filteredList=[];
        },
        setList:(state,action)=>{
            state.products=[]
            state.products=action.payload
            state.filteredProducts=action.payload
        },
        setIsLoading:(state,action)=>{
            state.isLoading=action.payload
        },
        initProducts:(state,action)=>{
            state.filteredProducts=state.products.slice(0,9)
        },
        changePage: (state, action) => {
            const x = action.payload-1;
            const startIndex = x * 9;
            const endIndex = startIndex + 9;
            state.filteredProducts = state.products.slice(startIndex, endIndex);
        },
    }
})

export const {clearList,setList,setIsLoading,changePage}=searchSlice.actions;
export default searchSlice.reducer