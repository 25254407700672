import { Button, Input, Spinner, useToast } from '@chakra-ui/react';
import axios from 'axios';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function SignIn(props) {
    const navigate = useNavigate();
    const username=useRef();
    const password=useRef();
    const [isLoading,setIsLoading]=useState(false);
    const toast=useToast()
    const handleLogin=()=>{
setIsLoading(true)
        axios.post(`${process.env.REACT_APP_HOSTURL_PROD}/user/signin`,{
            username:username.current.value,
            password:password.current.value
        }).then((res)=>{
            localStorage.setItem("token",res.data.token)
            if(res.data.user.role==="admin"){
                navigate("/")
            }

        }).catch((e)=>{
            setIsLoading(false)
            toast({
                title: 'Wrong credentials.',
                status: 'error',
                duration: 9000,
                isClosable: true,
              })
        })
    }
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-around", zIndex: 3, alignItems: "center", padding: 24, background: "#1F1F1F", borderEndEndRadius: "20px", borderEndStartRadius: 20 }}>
                <h1 className='logo-text' onClick={() => {
                    navigate("/")
                }}>
                    Comparit
                </h1>
                <div className='nav-bar'>
                    <h2 className='nav-item'>About us</h2>
                    <h2 className='nav-item' onClick={() => {
                        navigate("/notifications")
                    }}>Notifications</h2>
                    <h2 className='nav-item'>Add your shop</h2>
                </div>
                <svg style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="29" height="30" viewBox="0 0 29 30" fill="none">
                    <path d="M27.3333 28.0001L21.352 22.0081L27.3333 28.0001ZM24.6667 14.0001C24.6667 17.0059 23.4726 19.8885 21.3472 22.014C19.2218 24.1394 16.3391 25.3334 13.3333 25.3334C10.3275 25.3334 7.44487 24.1394 5.31946 22.014C3.19404 19.8885 2 17.0059 2 14.0001C2 10.9943 3.19404 8.11162 5.31946 5.98621C7.44487 3.86079 10.3275 2.66675 13.3333 2.66675C16.3391 2.66675 19.2218 3.86079 21.3472 5.98621C23.4726 8.11162 24.6667 10.9943 24.6667 14.0001V14.0001Z" stroke="white" strokeWidth="4" strokeLinecap="round" />
                </svg>
            </div>
            <div style={{ width: "50%",paddingTop:50 }}>
                <div style={{ display: "flex", flexDirection: "column", textAlign: "start" }}>
                    <label style={{fontFamily:"Lora"}}>Username</label>
                    <Input placeholder='Username' size='lg' ref={username} />
                </div>

                <div style={{ display: "flex", flexDirection: "column", textAlign: "start",marginBottom:20 }}>
                    <label style={{fontFamily:"Lora"}}>Password</label>
                    <Input placeholder='Password' type='password' size='lg' ref={password} />
                </div>
                <Button onClick={handleLogin} disabled={isLoading} bgColor={"var(--para-color)"}  _hover={{ background: "#ffbe86c0" }}>
                    
                    {isLoading?<Spinner/>:<p>Login</p>}
                </Button>

            </div>

        </div>
    );
}

export default SignIn;