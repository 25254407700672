import React, { useEffect } from 'react';
import "./Header.css"
import SearchBar from '../SearchBar/SearchBar';
import { useNavigate } from 'react-router-dom';
import { BellIcon } from '@chakra-ui/icons';
function Header(props) {
const navigate=useNavigate();
    return (
        <div className='header'>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", paddingTop: 24 }}>
                <h1 className='logo-text' onClick={()=>{
                    navigate("/")
                }}>
                    Comparit
                </h1>
                <div className='nav-bar'>
                    <h2 className='nav-item'>About us</h2>
                    <h2 className='nav-item' onClick={()=>{
                    navigate("/notifications")
                }}>Notifications</h2>
                    <h2 className='nav-item'>Add your shop</h2>
                </div>
                <BellIcon color={"white"} boxSize={10} onClick={()=>{
                    navigate("/notifications")
                }}/>
            </div>
            <h2 className='header-text'>
                Browse our <span style={{color:"var(--para-color)"}}>Skin Care</span> Category
            </h2>
            <div className='header-image'>
                <SearchBar updateUrl={props.updateUrl} wordsList={props.wordsList} lastRef={props.lastRef} setCached={props.setCached} />
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1440 588" fill="none" style={{transform:'translate(0,2%)'}}>
<g opacity="0.4">
<path d="M366.324 412.589C501.081 470.189 596.919 488.55 836.757 488.55L328.378 570.158L4.86487 454.312C80.7568 369.42 195.567 339.602 366.324 412.589Z" fill="white"/>
<path d="M366.324 412.589C501.081 470.189 596.919 488.55 836.757 488.55L328.378 570.158L4.86487 454.312C80.7568 369.42 195.567 339.602 366.324 412.589Z" fill="white" fillOpacity="0.85"/>
</g>
<path d="M1425.41 227.329C1433.19 169.171 1464.32 58.4843 1491.08 54.2632L1440 588H0V356.777C81.4054 409.15 305.341 505.923 525.892 505.923C718.541 505.923 789.568 479.829 851.838 455.269C952.054 415.744 986.108 397.304 1134.49 455.269C1315.95 526.158 1400.67 412.17 1425.41 227.329Z" fill="white"/>
<path d="M1425.41 227.329C1433.19 169.171 1464.32 58.4843 1491.08 54.2632L1440 588H0V356.777C81.4054 409.15 305.341 505.923 525.892 505.923C718.541 505.923 789.568 479.829 851.838 455.269C952.054 415.744 986.108 397.304 1134.49 455.269C1315.95 526.158 1400.67 412.17 1425.41 227.329Z" fill="white" fillOpacity="0.65"/>
</svg>
            </div>

        </div>
    );
}

export default Header;