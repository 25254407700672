
import './App.css';
import Skincare from './view/pages/Skincare/Skincare';
import { Route, Routes } from 'react-router-dom';
import { productTypeSkincareList } from './consts';
import Notification from './view/pages/Notifications/Notification';
import SignIn from './view/pages/singin/SignIn';
import PrivateRoute from './view/pages/privateRoute/PrivateRoute';

function App() {


  return (
    <div className="App">

      <Routes >
        <Route path="/signin" element={<SignIn />} />
        <Route path="/" element={<PrivateRoute allowedRoles={['admin']}>
          <Skincare productType={"skincare"} wordsList={productTypeSkincareList} /></PrivateRoute>} />
        <Route path="/notifications" element={<PrivateRoute allowedRoles={['admin']}>
          <Notification /></PrivateRoute>} />


      </Routes>


    </div>
  );
}

export default App;
