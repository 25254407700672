import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNotificationList, toggleIsLoading } from '../../../features/Notification';
import './Notifications.css'
import { Checkbox, Spinner } from '@chakra-ui/react';
import { AddIcon, BellIcon, CloseIcon, RepeatIcon, TriangleDownIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { UserName } from '../../../UserName';
function Notification() {
    const dispatch = useDispatch();
    const notifications = useSelector((state) => state.notificationStore.notifications)
    const isLoading = useSelector((state) => state.notificationStore.isLoading)
    const [totalNumberOfPages, setTotalNumberOfPages] = useState(null)


    useEffect(() => {

        const queryParams = new URLSearchParams(window.location.search);
        const initialState = queryParams.get('pageNumber');
        if (initialState) {
            setPageNumber(initialState);
        }

    }, []);


    const navigate = useNavigate();
    const username = UserName();


    const updateStateAndURL = (newStateValue) => {
        setPageNumber(newStateValue);
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set('pageNumber', newStateValue);
        window.history.replaceState(null, '', `?${queryParams.toString()}`);
    };


    const [pageNumber, setPageNumber] = useState(0);

    const [tunisiePara, setTunisiePara] = useState(false);
    const [maPara, setMapara] = useState(false);
    const [paraShop, setParaShop] = useState(false);
    const [pharmaShop, setPharmaShop] = useState(false);
    const [leCoinPara, setLeCoinPara] = useState(false);

    const [added, setAdded] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [inStock, setInStock] = useState(false);
    const [outOfStock, setOutOfStock] = useState(false);

    const lastRequestRef = useRef(null);
    const handleChange = (pageNumber) => {
        if (lastRequestRef.current) {
            lastRequestRef.current.cancel();
        }

        var parafilters = "";
        var typefilters = "";
        if (tunisiePara) {
            parafilters = parafilters + "tunisiepara,";
        }
        if (maPara) {
            parafilters = parafilters + "mapara,";
        }
        if (paraShop) {
            parafilters = parafilters + "parashop,";
        }
        if (pharmaShop) {
            parafilters = parafilters + "pharmashop,";
        }
        if (leCoinPara) {
            parafilters = parafilters + "lecoinpara,";
        }
        if (added) {
            typefilters = typefilters + "added product,"
        }
        if (updated) {
            typefilters = typefilters + "updated product,"
        }

        if (inStock) {
            typefilters = typefilters + "added stock,"
        }

        if (outOfStock) {
            typefilters = typefilters + "removed stock,"
        }

        const reqData = {
            typeFilter: typefilters,
            paraFilter: parafilters,
            page: pageNumber
        }
        const source = axios.CancelToken.source();
        lastRequestRef.current = source;
        var token = localStorage.getItem("token")
        const headers = {
            'Authorization': `Bearer ${token}`
        };
        dispatch(toggleIsLoading(true))
        const filterPromise = axios.post(`${process.env.REACT_APP_HOSTURL_PROD}/notification/find`, reqData, { cancelToken: source.token, headers });

        filterPromise
            .then((res) => {
                dispatch(setNotificationList(res.data.notifications))
                dispatch(toggleIsLoading(false))
                setTotalNumberOfPages(Math.floor(res.data.totalDocuments / 10) + 1)

            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                } else {
                    navigate("/signin")
                }
            });

    }
    useEffect(() => {
        handleChange(1);
        updateStateAndURL(1);
        return () => {
            if (lastRequestRef.current) {
                lastRequestRef.current.cancel();
            }
        };
    }, [tunisiePara, pharmaShop, maPara, tunisiePara, leCoinPara, paraShop, added, updated,inStock,outOfStock]);

    const navigateToExternalLink = (url) => {
        if (url && (url.startsWith('http://') || url.startsWith('https://'))) {
            window.open(url, '_blank');
        }
    };
    return (
        <div>
            <div style={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-around", zIndex: 3, alignItems: "center", padding: 24, background: "#1F1F1F", borderEndEndRadius: "20px", borderEndStartRadius: 20 }}>
                <h1 className='logo-text' onClick={() => {
                    navigate("/")
                }}>
                    Comparit
                </h1>
                <div className='nav-bar'>
                    <h2 className='nav-item'>About us</h2>
                    <h2 className='nav-item' onClick={() => {
                        navigate("/notifications")
                    }}>Notifications</h2>
                    <h2 className='nav-item'>Add your shop</h2>
                </div>
                <BellIcon color={"white"} boxSize={10} onClick={() => {
                    navigate("/notifications")
                }} />
            </div>

            <div className='main-box'>
                <div className='filters-box'>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <b style={{ fontFamily: "Lora" }}>
                            Notifications are deleted automatically every 24 hours
                        </b>
                        <div style={{ border: "2px var(--para-color) solid", cursor: "pointer" }} className='notif-icon' onClick={() => {
                            handleChange()
                        }} >
                            <RepeatIcon color={"var(--para-color)"} />

                        </div>
                        <div>

                        </div>
                    </div>

                    <h2 className='filter-name'>
                        By parapharmacy
                    </h2>
                    <div className='filter-container'>

                        <Checkbox colorScheme='green' value={tunisiePara} onChange={(e) => {
                            setTunisiePara(!tunisiePara);
                        }}>
                            Tunisie Para
                        </Checkbox>
                        <Checkbox colorScheme='green' value={maPara} onChange={(e) => {
                            setMapara(!maPara)

                        }}>
                            Ma Para
                        </Checkbox>
                        <Checkbox colorScheme='green' value={pharmaShop} onChange={(e) => {
                            setPharmaShop(!pharmaShop)


                        }}>
                            Pharma Shop
                        </Checkbox>
                        <Checkbox colorScheme='green' value={paraShop} onChange={(e) => {
                            setParaShop(!paraShop)
                        }}>
                            Para Shop
                        </Checkbox>
                        {
                            (username === "lecoinpara" || username === "parafendri") && <Checkbox colorScheme='green' value={leCoinPara} onChange={(e) => {
                                setLeCoinPara(!leCoinPara)


                            }}>
                                Le Coin Para
                            </Checkbox>
                        }


                    </div>

                    <h2 className='filter-name' style={{ marginTop: 20 }}>
                        By Type
                    </h2>
                    <div className='filter-container'>

                        <Checkbox colorScheme='green' value={added} onChange={(e) => {
                            setAdded(!added)

                        }}>
                            Added Product
                        </Checkbox>
                        <Checkbox colorScheme='green' value={updated} onChange={(e) => {
                            setUpdated(!updated)
                        }}>
                            Updated Price
                        </Checkbox>
                        <Checkbox colorScheme='green' value={inStock} onChange={(e) => {
                            setInStock(!inStock)
                        }}>
                            Added stock
                        </Checkbox>
                        <Checkbox colorScheme='green' value={outOfStock} onChange={(e) => {
                            setOutOfStock(!outOfStock)
                        }}>
                            Out of stock
                        </Checkbox>
                    </div>
                </div>

                {isLoading ? <div className='spinner-container'>
                    <Spinner size='xl' />
                </div> : <div className='notifications-box'>
                    {notifications && notifications.length === 0 ? <span>No notifications</span> : notifications.map((e, index) => {
                        var className = ""
                        var icon;
                        if (e.type === "updated product") {
                            className = "notif-box-update"
                            icon = <RepeatIcon color={"orange"} boxSize={6} />
                        } else if (e.type === "added product") {
                            className = "notif-box-add"
                            icon = <AddIcon color={"green"} />
                        } else if (e.type === "added stock") {
                            className = "notif-box-restock"
                            icon = <TriangleDownIcon color="blue" />
                        }
                        else if (e.type === "removed stock") {
                            className = "notif-box-out"
                            icon = <CloseIcon color="orange" />
                        }
                        const createdAt = new Date(e.createdAt);
                        const year = createdAt.getFullYear();
                        const month = String(createdAt.getMonth() + 1).padStart(2, '0');
                        const date = String(createdAt.getDate()).padStart(2, '0');
                        const hours = String(createdAt.getHours()).padStart(2, '0');
                        const minutes = String(createdAt.getMinutes()).padStart(2, '0');
                        const seconds = String(createdAt.getSeconds()).padStart(2, '0');

                        const formattedDate = `${year}/${month}/${date} ${hours}:${minutes}:${seconds}`;

                        return (
                            <div className={className} key={e.id}>
                                <div style={{ display: "flex", alignItems: "center" }}>

                                    <div className='notif-icon'>
                                        {icon}

                                    </div>
                                    <div>
                                        <b>{formattedDate}</b>
                                        <p>{e.content}</p>
                                    </div>
                                </div>
                                <div style={{ height: 100, width: 100, cursor: "pointer" }} onClick={() => {
                                    navigateToExternalLink(e.productLink)
                                }}>
                                    <img src={e.imageLink} alt='product-img'></img>
                                </div>
                            </div>)
                    })}


                    {
                        !isLoading && <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div className={pageNumber === 1 ? "inactive-chevron" : "active-chevron"} onClick={() => {
                                if (pageNumber !== 1) {
                                    updateStateAndURL(pageNumber - 1)
                                    handleChange(pageNumber - 1);

                                }
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clipPath="url(#clip0_40_12)">
                                        <path d="M15.41 16.59L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.59Z" fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_40_12">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            {totalNumberOfPages &&
                                Array.from({ length: totalNumberOfPages }, (_, index) => {
                                    const pageNumberInt = Number.parseInt(pageNumber);
                                    const indexInt = Number.parseInt(index);

                                    const isActive = indexInt + 1 === pageNumberInt;
                                    const isInRange = Math.abs(indexInt + 1 - pageNumberInt) <= 2;

                                    if (isInRange) {
                                        return (
                                            <div
                                                className={isActive ? 'active-number-div' : 'number-div'}
                                                style={isActive ? { border: "1px solid #ff933b", background: "var(--para-color)" } : null}
                                                key={index}
                                                onClick={() => {
                                                    updateStateAndURL(index + 1);
                                                    handleChange(index + 1)
                                                }}
                                            >
                                                {index + 1}
                                            </div>
                                        );
                                    } else if (indexInt === totalNumberOfPages - 1) {
                                        // Last page
                                        return (
                                            <>
                                                {indexInt - 2 < totalNumberOfPages - 1 && (
                                                    <div className="ellipsis" key={`ellipsis-after-${index}`}>
                                                        ...
                                                    </div>
                                                )}
                                                <div
                                                    className={isActive ? 'active-number-div' : 'number-div'}
                                                    key={index}
                                                    onClick={() => {
                                                        updateStateAndURL(index + 1);
                                                        handleChange(index + 1)

                                                    }}
                                                >
                                                    {index + 1}
                                                </div>

                                            </>
                                        );
                                    } else if (indexInt === 0) {
                                        return (
                                            <>
                                                <div
                                                    className={isActive ? 'active-number-div' : 'number-div'}
                                                    key={index}
                                                    onClick={() => {
                                                        updateStateAndURL(index + 1);
                                                        handleChange(index + 1)

                                                    }}
                                                >
                                                    {index + 1}
                                                </div>
                                                {indexInt + 2 < totalNumberOfPages - 1 && (
                                                    <div className="ellipsis" key={`ellipsis-before-${index}`}>
                                                        ...
                                                    </div>
                                                )}
                                            </>
                                        );
                                    } else {
                                        return (<div></div>);
                                    }
                                })
                            }


                            <div className={pageNumber === totalNumberOfPages ? "inactive-chevron" : "active-chevron"} onClick={() => {
                                if (pageNumber !== totalNumberOfPages) {
                                    updateStateAndURL(pageNumber + 1)
                                    handleChange(pageNumber + 1)

                                }

                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clipPath="url(#clip0_40_29)">
                                        <path d="M8.59 16.59L13.17 12L8.59 7.41L10 6L16 12L10 18L8.59 16.59Z" fill="black" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_40_29">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                    }
                </div>}
            </div>

        </div>
    );
}

export default Notification;