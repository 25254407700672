import React, { useEffect, useState } from 'react';
import "./ProductCard.css";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogOverlay, Button, Tag, TagLabel, TagLeftIcon, TagRightIcon, useDisclosure } from '@chakra-ui/react';
import { UserName } from '../../../UserName';
import { AddIcon, CheckIcon, CloseIcon, WarningIcon } from '@chakra-ui/icons';


/*
function calculatePercentageSaved(originalPrice, discountedPrice) {
  originalPrice = parseFloat(originalPrice);
  discountedPrice = parseFloat(discountedPrice)


  if (typeof originalPrice !== 'number' || typeof discountedPrice !== 'number') {
    return "Please provide valid numeric inputs for prices.";
  }

  if (discountedPrice >= originalPrice) {
    return "Discounted price should be less than the original price.";
  }

  const amountSaved = originalPrice - discountedPrice;

  const percentageSaved = (amountSaved / originalPrice) * 100;

  return percentageSaved.toFixed(2) + "%";
}
*/
function ProductCard(props) {

  const [maxWidth, setMaxWidth] = useState("70%");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setMaxWidth("90%"); // Adjust the maxWidth for mobile view
      } else {
        setMaxWidth("70%"); // Default maxWidth for larger screens
      }
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial call to set maxWidth based on the screen size
    handleResize();

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure()
  const username = UserName()

  const navigateToExternalLink = (url) => {
    if (url && (url.startsWith('http://') || url.startsWith('https://'))) {
      window.open(url, '_blank');
    }
  };


  var productImageUrl;
  if (props.product.pharmaShopImage) {
    productImageUrl = props.product.pharmaShopImage
  } else if (props.product.maParaImage) {
    productImageUrl = props.product.maParaImage
  } else if (props.product.paraShopImage) {
    productImageUrl = props.product.paraShopImage
  } else if (props.product.tunisieParaImage) {
    productImageUrl = props.product.tunisieParaImage

  } else if (props.product.leCoinParaImage) {
    productImageUrl = props.product.leCoinParaImage

  }



  return (
    <div className='product-card' onClick={() => {

      onOpen()
    }}>


      <img src={productImageUrl} style={{ width: "40%", height: 200 }} alt='product-img'></img>
      <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "60%", justifyContent: "space-between", paddingTop: 10, paddingBottom: 10, paddingRight: 15 }}>
        <h2 className='product-name'>{props.product.productName}</h2>
        {
          (username === "lecoinpara" || username === "parafendri") && props.product.leCoinParaPrice && !(isNaN(parseFloat(props.product.leCoinParaPrice))) &&
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div className='image-container'>
              <img src="../assets/lecoinpara.png" alt='le-coin-para'>
              </img>
            </div>

            <span className='price-text'>{props.product.leCoinParaPrice}
              <sup>
                DT
              </sup></span>
          </div>
        }
        {
          props.product.tunisieParaPrice && !(isNaN(parseFloat(props.product.tunisieParaPrice))) &&
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div className='image-container'>
              <img src="../assets/tunisiepara.png" alt='tunisie-para'>
              </img>
            </div>

            <span className='price-text'>{props.product.tunisieParaPrice}
              <sup>
                DT
              </sup></span>
          </div>
        }
        {
          props.product.maParaPrice && !(isNaN(parseFloat(props.product.maParaPrice))) &&
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div className='image-container'>
              <img src="../assets/mapara.webp" alt='ma-para'>
              </img>
            </div>

            <span className='price-text'>{props.product.maParaPrice}
              <sup>
                DT
              </sup></span>
          </div>
        }
        {
          props.product.pharmaShopPrice && !(isNaN(parseFloat(props.product.pharmaShopPrice))) &&
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div className='image-container'>
              <img src="../assets/pharmashop.png" alt='pharma-shop'>
              </img>
            </div>

            <span className='price-text'>{props.product.pharmaShopPrice}
              <sup>
                DT
              </sup></span>
          </div>
        }
        {
          props.product.paraShopPrice && !(isNaN(parseFloat(props.product.paraShopPrice))) &&
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div className='image-container'>
              <img src="../assets/parashop.webp" alt='para-shop'>
              </img>
            </div>

            <span className='price-text'>{props.product.paraShopPrice}
              <sup>
                DT
              </sup></span>
          </div>
        }




        <div className='detail-button'>
          Details
        </div>
      </div>
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        style={{ zIndex: "10000" }}
      >
        <AlertDialogOverlay >
          <AlertDialogContent maxWidth={maxWidth} flexDirection="column" justifyContent="center">
            <AlertDialogBody >
              <div>
                <div className='close-button' onClick={onClose}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                    <path d="M28.1176 26.57C28.2192 26.6717 28.2998 26.7923 28.3548 26.9251C28.4098 27.0579 28.4381 27.2002 28.4381 27.3439C28.4381 27.4876 28.4098 27.6299 28.3548 27.7627C28.2998 27.8954 28.2192 28.0161 28.1176 28.1177C28.016 28.2193 27.8953 28.2999 27.7625 28.3549C27.6298 28.4099 27.4875 28.4382 27.3437 28.4382C27.2 28.4382 27.0577 28.4099 26.925 28.3549C26.7922 28.2999 26.6715 28.2193 26.5699 28.1177L17.5 19.0464L8.43008 28.1177C8.22484 28.3229 7.94649 28.4382 7.65625 28.4382C7.36601 28.4382 7.08765 28.3229 6.88242 28.1177C6.67719 27.9125 6.56189 27.6341 6.56189 27.3439C6.56189 27.0536 6.67719 26.7753 6.88242 26.57L15.9537 17.5001L6.88242 8.4302C6.67719 8.22497 6.56189 7.94661 6.56189 7.65637C6.56189 7.36613 6.67719 7.08777 6.88242 6.88254C7.08765 6.67731 7.36601 6.56201 7.65625 6.56201C7.94649 6.56201 8.22484 6.67731 8.43008 6.88254L17.5 15.9538L26.5699 6.88254C26.7752 6.67731 27.0535 6.56201 27.3437 6.56201C27.634 6.56201 27.9123 6.67731 28.1176 6.88254C28.3228 7.08777 28.4381 7.36613 28.4381 7.65637C28.4381 7.94661 28.3228 8.22497 28.1176 8.4302L19.0463 17.5001L28.1176 26.57Z" fill="#C4C4C4" />
                  </svg>
                </div>
              </div>
              <div className="alert-dialog-container">
                <div className="product-main-image">
                  <img src={productImageUrl} alt={props.product.productCode}></img>
                </div>
                <h1 className='product-name-card2'>
                  {props.product.productName}
                </h1>
                <div className="product-details">

                  <h1 className='product-name-card'>
                    {props.product.productName}
                  </h1>
                  {
                    (username === "lecoinpara" || username === "parafendri") && props.product.leCoinParaPrice && !(isNaN(parseFloat(props.product.leCoinParaPrice))) &&
                    <div className='shop-card'>


                      <div className="product-image">
                        <img src={props.product.leCoinParaImage} alt={props.product.productCode} ></img>

                      </div>
                      {
                        props.product.productStockLeCoinPara && props.product.productStockLeCoinPara === "In stock" ? <Tag size="md" variant='outline' colorScheme='green'>
                          <TagLeftIcon boxSize='12px' as={CheckIcon} />

                          <TagLabel>{props.product.productStockLeCoinPara}</TagLabel>
                        </Tag> :
                        props.product.productStockLeCoinPara === "Out of stock"?
                         <Tag size="md" variant='outline' colorScheme='red'>
                          <TagLeftIcon boxSize='12px' as={CloseIcon} />

                          <TagLabel>{props.product.productStockLeCoinPara}</TagLabel>
                        </Tag>:<Tag size="md" variant='outline' colorScheme='blue'>
                          <TagLeftIcon boxSize='12px' as={WarningIcon} />

                          <TagLabel>No Data</TagLabel>
                        </Tag>
                      }

                      <div className="para-image">

                        <img src='../assets/lecoinpara.png' alt="le-coin-para"></img>

                      </div>
                      <Button onClick={() => {
                        navigateToExternalLink(props.product.productUrlLeCoinPara)
                      }} bgColor={"var(--para-color)"} _hover={{ background: "var(--para-secondary-color)" }} ><span>{props.product.leCoinParaPrice}
                          <sup>
                            DT
                          </sup></span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path d="M5.27587 6.66675H14.725C14.9653 6.66672 15.2028 6.71866 15.4212 6.819C15.6395 6.91934 15.8336 7.06571 15.9901 7.24808C16.1466 7.43045 16.2618 7.64451 16.3278 7.87557C16.3938 8.10663 16.4091 8.34923 16.3725 8.58675L15.3267 15.3801C15.2359 15.9705 14.9367 16.5088 14.4834 16.8977C14.03 17.2866 13.4524 17.5003 12.855 17.5001H7.14504C6.54787 17.5001 5.97044 17.2863 5.51723 16.8974C5.06402 16.5086 4.76498 15.9703 4.67421 15.3801L3.62837 8.58675C3.59185 8.34923 3.60712 8.10663 3.67313 7.87557C3.73914 7.64451 3.85434 7.43045 4.01083 7.24808C4.16732 7.06571 4.36139 6.91934 4.57974 6.819C4.7981 6.71866 5.03557 6.66672 5.27587 6.66675Z" stroke="#1F1F1F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M7.5 9.16667V5C7.5 4.33696 7.76339 3.70107 8.23223 3.23223C8.70107 2.76339 9.33696 2.5 10 2.5C10.663 2.5 11.2989 2.76339 11.7678 3.23223C12.2366 3.70107 12.5 4.33696 12.5 5V9.16667" stroke="#1F1F1F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </Button>

                    </div>


                  }
                  {
                    props.product.tunisieParaPrice && !(isNaN(parseFloat(props.product.tunisieParaPrice))) && <div className='shop-card'>


                      <div className="product-image">
                        <img src={props.product.tunisieParaImage} alt={props.product.productCode} ></img>

                      </div>
                      {
                        props.product.productStockTunisiePara && props.product.productStockTunisiePara === "In stock" ? <Tag size="md" variant='outline' colorScheme='green'>
                          <TagLeftIcon boxSize='12px' as={CheckIcon} />

                          <TagLabel>{props.product.productStockTunisiePara}</TagLabel>
                        </Tag> :
                        props.product.productStockTunisiePara === "Out of stock"?
                         <Tag size="md" variant='outline' colorScheme='red'>
                          <TagLeftIcon boxSize='12px' as={CloseIcon} />

                          <TagLabel>{props.product.productStockTunisiePara}</TagLabel>
                        </Tag>:<Tag size="md" variant='outline' colorScheme='blue'>
                          <TagLeftIcon boxSize='12px' as={WarningIcon} />

                          <TagLabel>No Data</TagLabel>
                        </Tag>
                      }
                      <div className="para-image">
                        <img src='../assets/tunisiepara.png' alt="tunisie-para"></img>

                      </div>
                      <Button onClick={() => {
                        navigateToExternalLink(props.product.productUrlTunisiePara)
                      }} bgColor={"var(--para-color)"} _hover={{ background: "var(--para-secondary-color)" }} ><span>{props.product.tunisieParaPrice}
                          <sup>
                            DT
                          </sup></span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path d="M5.27587 6.66675H14.725C14.9653 6.66672 15.2028 6.71866 15.4212 6.819C15.6395 6.91934 15.8336 7.06571 15.9901 7.24808C16.1466 7.43045 16.2618 7.64451 16.3278 7.87557C16.3938 8.10663 16.4091 8.34923 16.3725 8.58675L15.3267 15.3801C15.2359 15.9705 14.9367 16.5088 14.4834 16.8977C14.03 17.2866 13.4524 17.5003 12.855 17.5001H7.14504C6.54787 17.5001 5.97044 17.2863 5.51723 16.8974C5.06402 16.5086 4.76498 15.9703 4.67421 15.3801L3.62837 8.58675C3.59185 8.34923 3.60712 8.10663 3.67313 7.87557C3.73914 7.64451 3.85434 7.43045 4.01083 7.24808C4.16732 7.06571 4.36139 6.91934 4.57974 6.819C4.7981 6.71866 5.03557 6.66672 5.27587 6.66675Z" stroke="#1F1F1F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M7.5 9.16667V5C7.5 4.33696 7.76339 3.70107 8.23223 3.23223C8.70107 2.76339 9.33696 2.5 10 2.5C10.663 2.5 11.2989 2.76339 11.7678 3.23223C12.2366 3.70107 12.5 4.33696 12.5 5V9.16667" stroke="#1F1F1F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </Button>

                    </div>


                  }

                  {
                    props.product.paraShopPrice && !(isNaN(parseFloat(props.product.paraShopPrice))) &&
                    <div className='shop-card'>
                      <div className="product-image">
                        <img src={props.product.paraShopImage} alt={props.product.productCode}></img>

                      </div>
                      {
                        props.product.productStockParaShop && props.product.productStockParaShop === "In stock" ? <Tag size="md" variant='outline' colorScheme='green'>
                          <TagLeftIcon boxSize='12px' as={CheckIcon} />

                          <TagLabel>{props.product.productStockParaShop}</TagLabel>
                        </Tag> :
                        props.product.productStockParaShop === "Out of stock"?
                         <Tag size="md" variant='outline' colorScheme='red'>
                          <TagLeftIcon boxSize='12px' as={CloseIcon} />

                          <TagLabel>{props.product.productStockParaShop}</TagLabel>
                        </Tag>:<Tag size="md" variant='outline' colorScheme='blue'>
                          <TagLeftIcon boxSize='12px' as={WarningIcon} />

                          <TagLabel>No Data</TagLabel>
                        </Tag>
                      }
                      <div className="para-image">
                        <img src='../assets/parashop.webp' alt="para-shop"></img>

                      </div>

                      <Button bgColor={"var(--para-color)"} _hover={{ background: "var(--para-secondary-color)" }} onClick={() => {
                        navigateToExternalLink(props.product.productUrlParaShop)
                      }}><span>{props.product.paraShopPrice}
                          <sup>
                            DT
                          </sup></span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path d="M5.27587 6.66675H14.725C14.9653 6.66672 15.2028 6.71866 15.4212 6.819C15.6395 6.91934 15.8336 7.06571 15.9901 7.24808C16.1466 7.43045 16.2618 7.64451 16.3278 7.87557C16.3938 8.10663 16.4091 8.34923 16.3725 8.58675L15.3267 15.3801C15.2359 15.9705 14.9367 16.5088 14.4834 16.8977C14.03 17.2866 13.4524 17.5003 12.855 17.5001H7.14504C6.54787 17.5001 5.97044 17.2863 5.51723 16.8974C5.06402 16.5086 4.76498 15.9703 4.67421 15.3801L3.62837 8.58675C3.59185 8.34923 3.60712 8.10663 3.67313 7.87557C3.73914 7.64451 3.85434 7.43045 4.01083 7.24808C4.16732 7.06571 4.36139 6.91934 4.57974 6.819C4.7981 6.71866 5.03557 6.66672 5.27587 6.66675Z" stroke="#1F1F1F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M7.5 9.16667V5C7.5 4.33696 7.76339 3.70107 8.23223 3.23223C8.70107 2.76339 9.33696 2.5 10 2.5C10.663 2.5 11.2989 2.76339 11.7678 3.23223C12.2366 3.70107 12.5 4.33696 12.5 5V9.16667" stroke="#1F1F1F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </Button>



                    </div>
                  }
                  {
                    props.product.maParaPrice && !(isNaN(parseFloat(props.product.maParaPrice))) && <div className='shop-card'>

                      <div className="product-image">

                        <img src={props.product.maParaImage} alt={props.product.productCode}></img>
                      </div>
                      {
                        props.product.productStockMaPara && props.product.productStockMaPara === "In stock" ? <Tag size="md" variant='outline' colorScheme='green'>
                          <TagLeftIcon boxSize='12px' as={CheckIcon} />

                          <TagLabel>{props.product.productStockMaPara}</TagLabel>
                        </Tag> :
                        props.product.productStockMaPara === "Out of stock"?
                         <Tag size="md" variant='outline' colorScheme='red'>
                          <TagLeftIcon boxSize='12px' as={CloseIcon} />

                          <TagLabel>{props.product.productStockMaPara}</TagLabel>
                        </Tag>:<Tag size="md" variant='outline' colorScheme='blue'>
                          <TagLeftIcon boxSize='12px' as={WarningIcon} />

                          <TagLabel>No Data</TagLabel>
                        </Tag>
                      }
                      <div className="para-image">
                        <img src='../assets/mapara.webp' alt="ma-para"></img>
                      </div>
                      <Button onClick={() => {
                        navigateToExternalLink(props.product.productUrlMaPara)
                      }} bgColor={"var(--para-color)"} _hover={{ background: "var(--para-secondary-color)" }} ><span>{props.product.maParaPrice}
                          <sup>
                            DT
                          </sup></span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path d="M5.27587 6.66675H14.725C14.9653 6.66672 15.2028 6.71866 15.4212 6.819C15.6395 6.91934 15.8336 7.06571 15.9901 7.24808C16.1466 7.43045 16.2618 7.64451 16.3278 7.87557C16.3938 8.10663 16.4091 8.34923 16.3725 8.58675L15.3267 15.3801C15.2359 15.9705 14.9367 16.5088 14.4834 16.8977C14.03 17.2866 13.4524 17.5003 12.855 17.5001H7.14504C6.54787 17.5001 5.97044 17.2863 5.51723 16.8974C5.06402 16.5086 4.76498 15.9703 4.67421 15.3801L3.62837 8.58675C3.59185 8.34923 3.60712 8.10663 3.67313 7.87557C3.73914 7.64451 3.85434 7.43045 4.01083 7.24808C4.16732 7.06571 4.36139 6.91934 4.57974 6.819C4.7981 6.71866 5.03557 6.66672 5.27587 6.66675Z" stroke="#1F1F1F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M7.5 9.16667V5C7.5 4.33696 7.76339 3.70107 8.23223 3.23223C8.70107 2.76339 9.33696 2.5 10 2.5C10.663 2.5 11.2989 2.76339 11.7678 3.23223C12.2366 3.70107 12.5 4.33696 12.5 5V9.16667" stroke="#1F1F1F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </Button>

                    </div>

                  }
                  {
                    props.product.pharmaShopPrice && !(isNaN(parseFloat(props.product.pharmaShopPrice))) && <div className='shop-card'>


                      <div className="product-image">
                        <img src={props.product.pharmaShopImage} alt={props.product.productCode} ></img>

                      </div>
                      {
                        props.product.productStockPharmaShop && props.product.productStockPharmaShop === "In stock" ? <Tag size="md" variant='outline' colorScheme='green'>
                          <TagLeftIcon boxSize='12px' as={CheckIcon} />

                          <TagLabel>{props.product.productStockPharmaShop}</TagLabel>
                        </Tag> :
                        props.product.productStockPharmaShop === "Out of stock"?
                         <Tag size="md" variant='outline' colorScheme='red'>
                          <TagLeftIcon boxSize='12px' as={CloseIcon} />

                          <TagLabel>{props.product.productStockPharmaShop}</TagLabel>
                        </Tag>:<Tag size="md" variant='outline' colorScheme='blue'>
                          <TagLeftIcon boxSize='12px' as={WarningIcon} />

                          <TagLabel>No Data</TagLabel>
                        </Tag>
                      }
                      <div className="para-image">
                        <img src='../assets/pharmashop.png' alt="pharma-shop"></img>

                      </div>
                      <Button onClick={() => {
                        navigateToExternalLink(props.product.productUrlPharmaShop)
                      }} bgColor={"var(--para-color)"} _hover={{ background: "var(--para-secondary-color)" }} ><span>{props.product.pharmaShopPrice}
                          <sup>
                            DT
                          </sup></span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path d="M5.27587 6.66675H14.725C14.9653 6.66672 15.2028 6.71866 15.4212 6.819C15.6395 6.91934 15.8336 7.06571 15.9901 7.24808C16.1466 7.43045 16.2618 7.64451 16.3278 7.87557C16.3938 8.10663 16.4091 8.34923 16.3725 8.58675L15.3267 15.3801C15.2359 15.9705 14.9367 16.5088 14.4834 16.8977C14.03 17.2866 13.4524 17.5003 12.855 17.5001H7.14504C6.54787 17.5001 5.97044 17.2863 5.51723 16.8974C5.06402 16.5086 4.76498 15.9703 4.67421 15.3801L3.62837 8.58675C3.59185 8.34923 3.60712 8.10663 3.67313 7.87557C3.73914 7.64451 3.85434 7.43045 4.01083 7.24808C4.16732 7.06571 4.36139 6.91934 4.57974 6.819C4.7981 6.71866 5.03557 6.66672 5.27587 6.66675Z" stroke="#1F1F1F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M7.5 9.16667V5C7.5 4.33696 7.76339 3.70107 8.23223 3.23223C8.70107 2.76339 9.33696 2.5 10 2.5C10.663 2.5 11.2989 2.76339 11.7678 3.23223C12.2366 3.70107 12.5 4.33696 12.5 5V9.16667" stroke="#1F1F1F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </Button>

                    </div>


                  }

                </div>
              </div>
            </AlertDialogBody>


          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
}

export default ProductCard;