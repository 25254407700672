import { createSlice } from "@reduxjs/toolkit";
const notificationSlice=createSlice({
    name:"notification",
    initialState:{
        isLoading:false,
        notifications:[],
        filteredNotifications:[],
        paraFilter:"",
        typeFilter:"",

    },
    reducers:{
        setNotificationList:(state,action)=>{
            state.notifications=[]
            state.notifications=action.payload
            state.filteredNotifications=action.payload.slice(0,9)
        },
        changeNotificationPage: (state, action) => {
            const x = action.payload-1;
            const startIndex = x * 9;
            const endIndex = startIndex + 9;

            
            state.filteredNotifications = state.notifications.slice(startIndex, endIndex);
        },
        togglePara:(state,action)=>{
            
            if(state.paraFilter.includes(action.payload)){
                state.paraFilter=state.paraFilter.replace(`${action.payload},`,"")
            }else{
                state.paraFilter=state.paraFilter+action.payload+",";
            }
            
            
        },
        toggleType:(state,action)=>{

            if(state.typeFilter.includes(action.payload)){
                state.typeFilter=state.typeFilter.replace(`${action.payload},`,"")
            }else{
                state.typeFilter=state.typeFilter+action.payload+",";
            }
            

        },
        toggleIsLoading:(state,action)=>{
            state.isLoading=action.payload
        }
    }
})

export const {setNotificationList,changeNotificationPage,toggleIsLoading,togglePara,toggleType}=notificationSlice.actions;
export default notificationSlice.reducer