import React from "react";
import { Navigate } from "react-router-dom";
import { UserRole } from "../../../UserRole";
function PrivateRoute({ children, allowedRoles }) {
    const role = UserRole();
    if (role) {
            return (
                <div>
                    {allowedRoles.includes(role) ? children : <Navigate to="/" />}
                </div>)
        
       
    }
    else {
        return <Navigate to="/signin" />; 
    }
}
export default PrivateRoute;